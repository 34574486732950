<template>
  <div class="home">
    <v-row>
      <v-col lg="10" offset-md="1">
        <s-crud
          pdf
          title="Campañas"
          excel
          edit
          add
          remove
          :config="this.config"
          @save="saveCmp($event)"
          sortable
        >
          <template scope="props">
            <v-container v-if="props.item != null" class="pb-0">
              <v-row class="justify-center">
                <v-col cols="12" md="5" lg="4" class="s-col-form">
                  <s-text
                    v-model="props.item.CmpName"
                    label="Campaña"
                    autofocus
                  />
                </v-col>
                <v-col cols="4" md="2" class="s-col-form">
                  <s-date
                    label="Fecha Inicio"
                    v-model="props.item.CmpBeginDate"
                  />
                </v-col>
                <v-col cols="4" md="2" class="s-col-form">
                  <s-date label="Fecha Fin" v-model="props.item.CmpEndDate" />
                </v-col>
                <v-col cols="4" md="2" class="s-col-form">
                  <s-select-definition
                    :def="1030"
                    v-model="props.item.TypeCampaign"
                    label="Tipo Campaña"
                  />
                </v-col>
              </v-row>
            </v-container>
          </template>
        </s-crud>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _sCampaing from "@/services/General/CampaignService";

export default {
  components: {},
  data: () => ({
    dpCmpBeginDate: false,
    dpCmpEndDate: false,
    config: {
      title: "Campañas",
      service: _sCampaing,
      model: {
        CmpID: "ID",
        CmpName: "string",
        CmpBeginDate: "date",
        CmpEndDate: "date",
        SecStatus: "status",
        TypeCampaign: "boolean",
        TypeCampaignText: "string",
      },
      headers: [
        {
          text: "ID",
          value: "CmpID",
          sortable: true,
          align: "end",
          width: "10%",
        },
        {
          text: "Nombre",
          value: "CmpName",
          sortable: true,
          width: "40%",
        },
        {
          text: "Inicio",
          value: "CmpBeginDate",
          align: "center",
          width: "15%",
        },
        {
          text: "Fin",
          value: "CmpEndDate",
          align: "center",
          width: "15%",
        },
        {
          text: "Tipo",
          value: "TypeCampaignText",
          width: "20%",
        },
      ],
    },
  }),

  methods: {
    validatedCmp(item) {
      let isValid = false;
      let message = "";

      isValid = item.CmpName.length > 0;
      if (!isValid) message = "Ingrese nombre de campaña";
      else {
        isValid = this.$fun.isValidRangeDate(
          item.CmpBeginDate,
          item.CmpEndDate
        );
        if (!isValid) message = "Verifique rango de fecha";
        else {
          isValid = item.TypeCampaign > 0;
          if (!isValid) message = "Seleccione tipo de campaña";
        }
      }

      if (!isValid) this.$fun.alert(message, "warning");

      return isValid;
    },
    saveCmp(item) {
      if (this.validatedCmp(item)) item.save();
    },
  },
};
</script>
