import Service from "../Service";

const resource = "campaign/";

export default {
  save(cmp, requestID) {
    return Service.post(resource + "save", cmp, {
      params: { requestID: requestID },
    });
  },

  pagination(parameters, requestID) {
    return Service.post(resource + "pagination", parameters, {
      params: { requestID: requestID },
    });
  },
};
